var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-page"},[_vm._m(0),_c('div',{ref:"chatMain",staticClass:"msg-container"},_vm._l((_vm.messageList),function(msg,index){return _c('div',{key:index,class:[
        msg.sender,
        msg.type,
        { loading: msg.loading },
        { error: msg.error } ],on:{"click":function($event){return _vm.resend(msg, index)}}},[(msg.type === 'text')?_c('span',[_vm._v(" "+_vm._s(msg.content)+" ")]):(msg.type === 'image')?_c('div',{staticClass:"mesg-img"},[_c('el-image',{attrs:{"src":msg.image,"preview-src-list":[msg.image]}})],1):_c('div',{on:{"click":function($event){return _vm.downloadFile(msg)}}},[_c('div',{staticClass:"content"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(msg.file.name))]),_c('span',{staticClass:"size"},[_vm._v(_vm._s(_vm.fileSize(msg.file.size)))])]),_vm._m(1,true),_c('div',{staticClass:"progress-bar",style:({
            '--progress': msg.uploadProgress,
            display: msg.uploadProgress === '100%' ? 'none' : 'block',
          })})])])}),0),(_vm.isMsg)?_c('button',{staticClass:"attachment-btn",on:{"click":function($event){return _vm.$emit('openAttachment')}}}):_vm._e(),_c('div',{staticClass:"msg-input-area"},[_c('div',{staticClass:"input"},[_c('el-input',{attrs:{"type":"textarea","resize":"none"},nativeOn:{"keydown":function($event){return _vm.handleKeyCode($event)}},model:{value:(_vm.sendText),callback:function ($$v) {_vm.sendText=$$v},expression:"sendText"}}),_c('button',{staticClass:"upload",on:{"click":_vm.clickUpload}}),_c('input',{ref:"fileInput",attrs:{"type":"file","id":"file"},on:{"change":_vm.changeFile}})],1),_c('button',{staticClass:"send",on:{"click":_vm.handleTextSend}})]),_c('audio',{ref:"audio",staticStyle:{"display":"none"},attrs:{"controls":"controls","src":require("../../../assets/music/mesg_auto.mp3")}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"doctor"},[_c('div'),_c('span',[_vm._v("醫生")])]),_c('div',{staticClass:"employee"},[_c('div'),_c('span',[_vm._v("患者")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"icon-file"})])}]

export { render, staticRenderFns }