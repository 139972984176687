<template>
  <div
    class="attachment-page"
    ref="page"
    :class="{ active, fixed: isFolderOpen }"
  >
    <button class="back" @click="back" :class="{ active }">
      <i class="icon-leftarrow"></i>
      <span>返回</span>
    </button>
    <div class="data-container">
      <h1>病人資料</h1>
      <div class="grid-container">
        <div>姓名</div>
        <div>{{ caseData.name }}</div>
        <div>性別</div>
        <div>{{ genderInText }}</div>
        <div>年齡</div>
        <div>{{ caseData.age }}</div>
        <div>出生日期</div>
        <div>{{ caseData.birthday }}</div>
        <div>電話號碼</div>
        <div>{{ caseData.phone }}</div>
        <div>備注</div>
        <div>{{ caseData.remark }}</div>
        <div>附件</div>
        <div>
          <button
            v-for="(attachmentFolder, index) in caseData.attachmentList"
            :key="index"
            class="folder"
            @click="openFolder(attachmentFolder)"
          >
            <span>
              <i class="icon-folder"></i>
              {{ attachmentFolder.folder }}
            </span>
            <i class="icon-rightarrow"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="uploader">
      <el-upload class="dashed" :onSuccess="uploadSuccess">
        <span class="placeholder">上傳附件</span>
      </el-upload>
      <div class="notes">
        <span>請上傳相關文件</span>
        <span>如：病歷、相、情況等</span>
        <span>*每個檔案不能超過10mb</span>
      </div>
    </div>
    <!-- 跟进GlobalMedicare的改动，这部分内容不对病人进行显示 -->
    <!-- <div class="data-container">
            <h1>診症資料</h1>
            <div class="grid-container">
                <div>用戶症狀</div>
                <div>
                    {{
                        caseData.patientSymptoms
                            ? caseData.patientSymptoms
                            : "等待醫生填寫"
                    }}
                </div>
                <div>醫生診斷</div>
                <div>
                    {{
                        caseData.doctorDiagnosis
                            ? caseData.doctorDiagnosis
                            : "等待醫生填寫"
                    }}
                </div>
                <div>治療程序</div>
                <div>
                    {{
                        caseData.treatmentPlan
                            ? caseData.treatmentPlan
                            : "等待醫生填寫"
                    }}
                </div>
                <div>醫生附件</div>
                <div>
                    <button
                        v-for="(
                            attachmentFolder, index
                        ) in caseData.doctorReportAttachmentList"
                        :key="index"
                        class="folder"
                        @click="openFolder(attachmentFolder)"
                    >
                        <span>
                            <i class="icon-folder"></i>
                            {{ attachmentFolder.folder }}
                        </span>
                        <i class="icon-rightarrow"></i>
                    </button>
                </div>
            </div>
        </div> -->
    <div class="folder-page" :class="{ active: isFolderOpen }">
      <div class="folder-title">
        <i class="icon-folder"></i>
        {{ activeFolder.folder }}
      </div>
      <span class="file" v-for="file in activeFolder.attachment" :key="file.id">
        <i class="icon-subdirectoryarrowright"></i>
        <a
          @click="openFile(file.url)"
          target="_blank"
          style="text-decoration: underline"
        >
          {{ file.title }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import { casesDetail, updCases } from "@/api";
export default {
  data: function () {
    return {
      caseData: {
        name: "",
        gender: "",
        age: "",
        birthday: "",
        phone: "",
        attachmentList: [],
        remark: "",
        doctorDiagnosis: "",
        treatmentPlan: "",
        patientSymptoms: "",
        doctorReportAttachmentList: [],
      },
      activeFolder: {},
      isFolderOpen: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    caseId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    genderInText: function () {
      return this.caseData.gender == 1 ? "男" : "女";
    },
  },
  watch: {
    caseId: function () {
      if (this.caseId != -1) {
        this.getData();
      }
    },
    active: function () {
      if (this.active) {
        this.getData();
      }
    },
  },
  methods: {
    openFile(url) {
      this.$message({
        showClose: true,
        type: "success",
        message: "正在打開中請耐心等待",
      });
      if (url.indexOf(".pdf") >= 0) {
        window.open("https://docs.google.com/viewer?embedded=true&url=" + url);
      } else if (
        url.indexOf(".doc") >= 0 ||
        url.indexOf(".docx") >= 0 ||
        url.indexOf(".xlsx") >= 0 ||
        url.indexOf(".xls") >= 0 ||
        url.indexOf(".ppt") >= 0 ||
        url.indexOf(".pptx") >= 0
      ) {
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      } else {
        window.open(url);
      }
    },
    getData() {
      casesDetail(this.caseId).then((res) => {
        let data = this.$camel(res.data);
        for (let key of Object.keys(this.caseData)) {
          if (Object.keys(data).includes(key)) {
            this.caseData[key] = data[key];
          }
        }
      });
    },
    uploadSuccess(res) {
      let filePath = res.data.filePath;
      let attachment = [filePath];
      updCases(this.caseId, { attachment }).then((res) => {
        this.caseData.attachmentList = res.data.attachmentList;
      });
    },
    openFolder(attachmentFolder) {
      this.$refs.page.scrollTop = 0;
      this.activeFolder = attachmentFolder;
      this.isFolderOpen = true;
    },
    back() {
      if (this.isFolderOpen) this.isFolderOpen = false;
      else this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-page {
  @include open-from-right;
  transition-duration: 0.3s;
  z-index: 100;
  padding-top: 16px;
  background-color: $white;
  overflow: auto;
  &.active {
    transform: scaleX(1);
    button {
      left: 0;
    }
  }
  &.fixed {
    transform: scaleX(1);
    height: 100vh;
    overflow: hidden;
  }
  .uploader {
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 16px;
    margin-top: -16px;
    margin-bottom: 16px;
    column-gap: 16px;
    align-items: center;
    span.placeholder {
      @include flexc-center-center;
    }
    .notes {
      @include flexc-start-stretch;
      span {
        font-size: 14px;
      }
      span:first-of-type {
        font-size: 18px;
      }
    }
  }
  .back {
    color: $green-300;
    font-size: 24px;
    font-weight: $semi-bold;
    background-color: transparent;
    padding: 8px;
    margin-bottom: 16px;
    transition: 0.3s all 0.2s ease;
    position: relative;
    left: -100%;
  }
  .folder {
    width: 100%;
    background-color: transparent;
    color: $yellow-150;
    font-size: 16px;
    @include flexr-between-center;
  }
  .folder-page {
    @include open-from-right;
    height: calc(100vh - 64px);
    top: auto;
    bottom: 0;
    &.active {
      transform: scaleX(1);
    }
    background-color: $white;
    transition-duration: 0.3s;
    @include flexc-start-stretch;
    padding: 0 16px 16px;
    .folder-title {
      font-size: 21px;
      color: $yellow-150;
    }
    .file,
    .file a,
    .file a:visited {
      font-size: 16px;
      color: $black;
    }
  }
}
</style>