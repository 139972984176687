<template>
  <div class="chat-page">
    <div class="legend">
      <div class="doctor">
        <div></div>
        <span>醫生</span>
      </div>
      <div class="employee">
        <div></div>
        <span>患者</span>
      </div>
    </div>
    <div class="msg-container" ref="chatMain">
      <div
        v-for="(msg, index) in messageList"
        :key="index"
        :class="[
          msg.sender,
          msg.type,
          { loading: msg.loading },
          { error: msg.error },
        ]"
        @click="resend(msg, index)"
      >
        <span v-if="msg.type === 'text'">
          {{ msg.content }}
        </span>
        <div v-else-if="msg.type === 'image'" class="mesg-img">
          <el-image :src="msg.image" :preview-src-list="[msg.image]"></el-image>
        </div>
        <div v-else @click="downloadFile(msg)">
          <div class="content">
            <span class="title">{{ msg.file.name }}</span>
            <span class="size">{{ fileSize(msg.file.size) }}</span>
          </div>
          <div class="icon"><i class="icon-file"></i></div>
          <div
            class="progress-bar"
            :style="{
              '--progress': msg.uploadProgress,
              display: msg.uploadProgress === '100%' ? 'none' : 'block',
            }"
          ></div>
        </div>
      </div>
    </div>
    <button
      v-if="isMsg"
      class="attachment-btn"
      @click="$emit('openAttachment')"
    ></button>
    <div class="msg-input-area">
      <div class="input">
        <el-input
          v-model="sendText"
          type="textarea"
          resize="none"
          @keydown.native="handleKeyCode($event)"
        ></el-input>
        <button class="upload" @click="clickUpload"></button>
        <input ref="fileInput" type="file" id="file" @change="changeFile" />
      </div>
      <button @click="handleTextSend" class="send"></button>
    </div>

    <audio
      style="display: none"
      ref="audio"
      controls="controls"
      src="../../../assets/music/mesg_auto.mp3"
    ></audio>
  </div>
</template>

<script>
import { mapState } from "vuex";
import chatMixin from "../chatroomMixins/chatMixin";
import { chatroomMessages } from "@/api";
export default {
  mixins: [chatMixin],
  data: function () {
    return {
      userType: "patient",
    };
  },
  computed: {
    ...mapState({
      userSig: (state) => state.user.userSig,
      userID: (state) => state.user.userId,
      tim: (state) => state.chat.tim,
      isReady: (state) => state.chat.isReady,
    }),
  },
  methods: {
    chatroomMessages,
  },
  created() {
    this.setAutoPlayer();
  },
};
</script>

<style lang="scss" scoped>
@include chat-scss;
.mesg-img {
  min-height: 65px;
  min-width: 65px;
}
</style>